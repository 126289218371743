import * as React from 'react';
import { LocalStorageClass, Redirect } from "../Util";
import { Alert, AlertTitle, Box, Typography, Snackbar, ListItemText, ListItemButton } from '@mui/material';
import Grid from '@mui/material/Grid2';

import Topbar from '../components/Topbar';
import GulPanel from '../components/GulPanel'

// PID: 0
export default function Main() {
    const LSC = new LocalStorageClass();
    let success = null;
    let [alertopen, setAlertopen] = React.useState(true);
    const closeEvent = (e, r) => {
        if (r === "clickaway") return;
        setAlertopen(false);
    }

    let GulPanelList = [];
    const gullist = [{
        uid: 1,
        name: "윤석열 비상계엄에 대한 고찰",
        author: "전한길",
        date: "2024.12.03",
        view: 167204,
        comment: 1582
    },  {
        uid: 2,
        name: "정보동아리 들오삼",
        author: "Dwk0910",
        date: "2024.12.18",
        view: 0,
        comment: 0
    }, {
        uid: 3,
        name: "와 샌즈 아시는구나!",
        author: "Bluepig",
        date: "2024.12.18",
        view: 0,
        comment: 0
    }];

    gullist.forEach((e) => {
        let gul = {
            uid: e.uid,
            name: e.name,
            author: e.author,
            date: e.date,
            view: e.view,
            comment: e.comment
        }

        GulPanelList.push(<GulPanel Gul={gul}/>);
    });

    const data = ["공","지","사","항","모","음","와! 샌즈!"];
    let datacomponents = [];
    const AnnouncementItemHeight = 78;
    const AnnouncementHeight = data.length * AnnouncementItemHeight;
    data.forEach((item) => {
        datacomponents.push(
            <ListItemButton sx={{borderTop:"1px solid #000000",width:"100%",height:{AnnouncementItemHeight}}}>
                <ListItemText primary={item[0]} primaryTypographyProps={{fontFamily:"SeoulNamsanM",fontSize:"1.4rem",fontWeight:"bold"}} onClick={()=>{
                    Redirect(item[1], false);
                }}></ListItemText>
            </ListItemButton>
        );
    });

    if (LSC.get("event") !== null) {
        const target = LSC.get("event") === "login" ? "로그인" : "로그아웃";
        success = (
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                autoHideDuration={3500}
                open={alertopen}
                onClose={closeEvent}
                sx={{ paddingLeft: "1%", paddingRight: "1%" }}
            >
                <Alert>
                    <AlertTitle>
                        <Typography sx={{ fontFamily: "SeoulNamsanEB", fontSize: "1.2rem" }}>
                            성공
                        </Typography>
                    </AlertTitle>
                    <Typography sx={{ fontFamily: "SeoulNamsanC" }}>{target}이 성공적으로 완료되었습니다!</Typography>
                </Alert>
            </Snackbar>
        );
        LSC.remove("event");
    }

    return (
        <React.Fragment>
            <Topbar/>
            { success }
            <Grid container spacing={1} sx={{ ml: 3, mr: 3, mt: 3 }}>
                <Grid size={8}>
                    <Box sx={{
                        display: 'flex',
                        border: '1px solid black',
                        height: 185,
                        justifyContents: 'center'
                    }}>[ 여기에 사진 넣기 ]</Box>
                    <Typography sx={{
                        fontFamily: "SeoulNamsanM",
                        fontSize: '1.5rem',
                        fontWeight: "bold",
                        color: 'gray',
                        pl: 2,
                        mt: 3,
                    }}>· 추천 글</Typography>
                    {GulPanelList}
                </Grid>
                <Grid size={4}>
                    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",height:{AnnouncementHeight},border:"1px solid black"}}>
                        <Typography sx={{fontFamily:"SeoulNamsanM",fontSize:"1.8rem",fontWeight:"bold",mt:1,mb:1}}>공지사항</Typography>
                        {datacomponents}
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}