import { Box, Typography } from '@mui/material';
import { Redirect } from '../Util';

export default function GulPanel({ Gul }) {
    /*
    Gul = [{
        uid: 1
        name: "정보동아리 들오삼",
        author: "Dwk0910",
        date: "2024.12.18",
        view: 0,
        comment: 0
     */

    return (
        <Box className={"gulbox"} sx={{ display: "flex", flexDirection: "row", p: "1%", m: "1%", border: "1px solid gray", borderRadius: "5px" }} onClick={() => {
            Redirect(".?pid=8&target=" + Gul.uid, false);
        }}>
            <Box sx={{ width: "60%" }}>
                <Typography sx={{ fontFamily: "SeoulNamsanM", fontWeight: "bold", fontSize: "1.2rem" }}>{Gul.name}</Typography>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography sx={{ fontFamily: "SeoulNamsanM", fontSize: "0.8rem", color: "gray" }}>{ Gul.date }</Typography>
                    <Typography sx={{ fontFamily: "SeoulNamsanM", fontSize: "0.8rem", color: "gray", ml: "1%", mr: "1%" }}>·</Typography>
                    <Typography sx={{ fontFamily: "SeoulNamsanM", fontSize: "0.8rem", color: "gray" }}>{ Gul.author }</Typography>
                </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "40%" }}>
                <Typography sx={{ fontFamily: "SeoulNamsanM", color: "gray" }}>조회수</Typography>
                <Typography sx={{ fontFamily: "SeoulNamsanM", color: "black", ml: "1%" }}>{ Gul.view }</Typography>
                <Typography sx={{ fontFamily: "SeoulNamsanM", color: "gray", ml: "2%" }}>댓글</Typography>
                <Typography sx={{ fontFamily: "SeoulNamsanM", color: "black", ml: "1%" }}>{ Gul.comment }</Typography>
            </Box>
        </Box>
    );
}