import { TextField, Button, Box, Typography, Divider, Checkbox, FormControlLabel } from "@mui/material";
import * as React from "react";
import "../../fonts.css";
import "../../components/Topbar";
import TopBar from "../../components/Topbar";
import Swal from 'sweetalert2';
import $ from "jquery";
import { Redirect } from "../../Util";

// PID: 6

/*
****
ID와 이름(실명)을 따로 받아야 할 것 같음
ID : 최소 3자 / 최대 40자 (TopBar크기 맞춰서 계산했), Only 영어
이름 : 최소 2자 / 최대 5자, 무조건 실명 (선생님 확인용)
****
 */
export default function Register() {
    //RD = Register Diabled임
    let [Check, setCheck] = React.useState(false);
    let [Name, setName] = React.useState("");
    let [Password, setPassword] = React.useState("");
    let [SecretCode, setSecretCode] = React.useState("");
    let [RealName, setRealName] = React.useState("");
    let [ButtonText, setButtonText] = React.useState("가입하기");
    let [ButtonColor, setButtonColor] = React.useState("rgb(0,100,255)");

    function ChangeCheckbox() {
        setCheck(!Check)
    }

    window.onload = () => {
        const formtag = document.getElementById("form");
        formtag.addEventListener("submit", (e) => {
            // post action 억제
            e.preventDefault();
            return e.target;
        });
    }

    return (
        <React.Fragment>
            <TopBar></TopBar>
            <form id={"form"} method={"POST"} onSubmit={() => {
                //console.log(Password)
                $.ajax({
                    url: 'https://neatorebackend.kro.kr/backend/swmakers/Signup.php',
                    type: 'post',
                    data: {
                        ctpd: process.env.REACT_APP_CTPD,
                        id: Name,
                        pwd: Password,
                        name: RealName
                    },
                    error: () => {
                        console.log("error")
                    }
                }).then((response) => {
                    if (JSON.parse(response).status === "success") {
                        //성공!
                        //console.log("성공 ㅅㄱ")
                        Swal.fire({
                            title: "<span style=\"font-family:SeoulNamsanM, monospace\">완료</span>",
                            text: "<span style=\"font-family: SeoulNamsanC, monospace\">회원가입이 성공적으로 완료되었습니다.</span>",
                            icon: "success",
                            showConfirmButton: true,
                            confirmButtonText: "확인"
                        }).then(() => {
                            Redirect(".?pid=3", false)
                        })
                    } else if (response.status === "idexist") {
                        //이미 아이디 존재
                        setButtonColor("rgb(255,0,0)")
                        setButtonText("이미 존재하는 아이디입니다.")
                    } else if (JSON.parse(response).status === "INVALID KEY") {
                        //인터널 서버 에러
                        //console.log("서버 에러 ㅅㄱ")
                        //console.log(process.env.REACT_APP_CTPD)
                    } else {
                        //console.log(response)
                    }
                })
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 15,
                    mb: 15,
                    flexDirection: "column"
                }}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 600,
                        height: 600,
                        backgroundColor: "rgb(255, 220,220)",
                        borderRadius: "10px",
                        boxShadow: 20,
                        flexDirection: "column"
                    }}>
                        <Typography sx={{fontFamily: "yg-jalnan", fontSize: "3.5rem", color: "rgb(50,50,50)"}}>
                            회원가입
                        </Typography>
                        <TextField variant="outlined" label="아이디" sx={{mt: 5, width: 280}} onChange={(event) => {
                            event.target.value = event.target.value.replace(/[^A-Za-z1234567890]/ig, '');
                            setName(event.target.value);
                        }}></TextField>
                        <TextField type="password" variant="outlined" label="비밀번호" sx={{mt: 1, width: 280}}
                                   onChange={(event) => {
                                       setPassword(event.target.value)
                                   }}></TextField>
                        <TextField variant="outlined" label="실명" sx={{mt: 1, width: 280}} onChange={(event) => {
                            setRealName(event.target.value)
                        }}></TextField>
                        <Divider orientation="horizontal" flexItem sx={{mt: 2, width: 400, ml: 12.5}}
                                 variant="middle"></Divider>
                        <TextField required variant="outlined" label="참가코드" sx={{mt: 2, width: 250}}
                                   onChange={(event) => {
                                       setSecretCode(event.target.value)
                                   }}></TextField>
                        <FormControlLabel sx={{mt: 0.5}} label="개인정보 수집 및 이용에 동의합니다."
                                          control={<Checkbox onClick={() => {
                                              ChangeCheckbox()
                                          }}></Checkbox>}></FormControlLabel>
                        <Button type="submit" variant="outlined" sx={{
                            mt: 2.5,
                            fontFamily: "SeoulNamsanM",
                            fontWeight: "bold",
                            width: 250,
                            height: 50,
                            fontSize: "1.5rem",
                            color: ButtonColor,
                            borderColor: ButtonColor
                        }} disabled={!(Check && Name !== "" && Password !== "" && SecretCode !== "" && RealName !== "")}>
                            {ButtonText}
                        </Button>
                    </Box>
                </Box>
            </form>
        </React.Fragment>
    );
}