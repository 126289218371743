import '../../fonts.css';
import * as React from 'react';
import logo from '../../files/404gif.gif';
import { Box, Button, Typography } from '@mui/material';
import { Redirect } from '../../Util.js';
import TopBar from '../../components/Topbar.js';

export default function Error404() {
  return (
    <>
      <TopBar></TopBar>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh', mt:"-86.66px" }}>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <img src={logo} style={{ width: 500 }} alt={'404logo'}/>
              <Typography sx={{ fontFamily: "SeoulNamsanM", fontWeight: 'bold', fontSize: '1.6rem' }}>Error 404 : PAGE NOT FOUND</Typography>
              <Typography sx={{ fontFamily: "Orbit", textAlign: 'center', fontSize: '1.2rem', mt: 3 }}>
                  입력하신 주소의 페이지가 이동하였거나 삭제되었습니다.<br/>
                  주소가 올바른지 확인하신 후, 다시 시도해주세요.
              </Typography>
              <Button variant={"contained"} sx={{ mt: 6, width: 200, height: 50, fontFamily: 'SeoulNamsanM', fontSize: '1.05rem', backgroundColor: '#6facea' }} onClick={() => Redirect(".")}>홈으로 돌아가기</Button>
          </Box>
      </Box>
    </>
  );
}