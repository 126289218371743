import { Box, Typography } from "@mui/material";
import under_construction from '../files/u_construction.png';

export default function Gong4() {
    return (
        <>
            <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", height:"100vh", mt:"-86.66px"}}>
                <img src={under_construction} style={{width:450, marginRight: 35}}></img>
                <Typography sx={{fontSize:"4rem",fontFamily:"SeoulNamsanM",fontWeight:"bold",mt:5}}>
                    공사중...
                </Typography>
            </Box>
        </>
    )
}