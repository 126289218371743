import Gong4 from '../components/Gong4';
import TopBar from '../components/Topbar';

//PID: 1
export default function Introduction() {
  return (
    <div>
        <TopBar/>
        <Gong4/>
    </div>
  );
}
