import * as React from 'react';
import Swal from 'sweetalert2';
import { Link } from "@mui/material";

import { Redirect } from '../../Util';
import TopBar from '../../components/Topbar';

export default function PageViewer() {
    const urlParameter = new URL(window.location.href).searchParams;
    let pageId = urlParameter.get('target');

    if (pageId === null || parseInt(pageId) % 1 !== 0 || pageId <= 0) {
        Swal.fire({
            title: "<span style=\"font-family: SeoulNamsanM, monospace\">오류</span>",
            text: "TARGET이 없거나 잘못되었습니다.",
            icon: "error",
            showConfirmButton: true,
            confirmButtonText: "확인"
        }).then(() => {
            Redirect(".", false);
        });
    } else {
        return (
            <React.Fragment>
                <TopBar/>
                <Link style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }} onClick={() => { window.history.back(); }}>GOBACK</Link><br/>
                PageViewer target : { pageId }
            </React.Fragment>
        );
    }
}