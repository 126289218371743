import * as React from "react";
import { TextField, Button, Box, Typography, keyframes} from "@mui/material";
import { LocalStorageClass, Redirect } from "../../Util";
import "../../fonts.css";
import "../../components/Topbar"
import TopBar from "../../components/Topbar";
import Swal from "sweetalert2";
import $ from "jquery";

//PID: 3
export default function Login() {
  const shakeAnimation = keyframes`
    0% { transform: translateX(0); }
    12.5% { transform: translateX(-5px); }
    25% { transform: translateX(5px); }
    62.5% {transform: translateX(-5px); }
    75% { transform: translateX(5px); }
    100% { transform: translateX(0); }
  `;

  let [Id, changeId] = React.useState("");
  let [Pw, changePw] = React.useState("");
  let [buttonText, changeButtonText] = React.useState("로그인");
  let [buttonColor, changeButtonColor] = React.useState("rgb(0,100,255)");
  let [isShaking, setIsShaking] = React.useState(false);

  const LSC = new LocalStorageClass();

  if (LSC.get("Id") !== null) {
      Swal.fire({
          title: "<span style=\"font-family: SeoulNamsanM, monospace\">오류</span>",
          text: "잘못된 접근입니다.",
          icon: "error",
          showConfirmButton: true,
          confirmButtonText: "확인"
      }).then(() => {
          Redirect(".");
      });
  }

  window.onload = () => {
      const formtag = document.getElementById("form");
      formtag.addEventListener("submit", (e) => {
          // form action 억제
          e.preventDefault();
          return e.target;
      });
  }

  return (
      <React.Fragment>
          <TopBar/>
          <form id={"form"} method={"POST"} onSubmit={() => {
              if (Id !== "" && Pw !== "") {
                  $.ajax({
                      url: 'https://neatorebackend.kro.kr/backend/swmakers/Login.php',
                      type: 'post',
                      data: {
                          ctpd: process.env.REACT_APP_CTPD,
                          id: Id,
                          pwd: Pw
                      },
                      error: () => {
                          console.log("error")
                      }
                  }).then((response) => {
                      if (JSON.parse(response).status) {
                          LSC.set("Id", Id);
                          LSC.set("Pw", Pw);
                          LSC.set("event", "login");
                          Redirect(".", false);
                      } else {
                          changeButtonText("계정이 올바르지 않습니다.")
                          changeButtonColor("rgb(255,0,0)");
                          setIsShaking(true);
                          setTimeout(() => setIsShaking(false), 500);
                      }
                  })
              } else {
                  changeButtonColor("rgb(255,0,0)");
                  setIsShaking(true);
                  setTimeout(() => setIsShaking(false), 500);
              }
              if (Id === "") changeButtonText("아이디를 적어주세요");
              if (Pw === "") changeButtonText("비밀번호를 적어주세요");
              if (Id === "" && Pw === "") changeButtonText("아이디와 비밀번호를 적어주세요");
          }}>
              <Box
                  sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      mt: 15,
                      mb: 15,
                  }}
              >
                  <Box
                      sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "rgb(192, 252, 255)",
                          borderRadius: "10px",
                          width: 500,
                          boxShadow: 20,
                      }}
                  >
                      <Typography
                          sx={{fontSize: "2.5rem", mt: 5, fontFamily: "yg-jalnan"}}
                      >
                          로그인
                      </Typography>
                      <TextField
                          label="아이디"
                          variant="outlined"
                          sx={{mt: 2, mb: 1, width: 300}}
                          onChange={(event) => {
                              changeId(event.target.value);
                          }}
                          required
                      ></TextField>
                      <TextField
                          type="password"
                          required
                          label="비밀번호"
                          variant="outlined"
                          onChange={(event) => {
                              changePw(event.target.value);
                          }}
                          sx={{width: 300}}
                      ></TextField>
                      <Button
                          variant="contained"
                          type={"submit"}
                          sx={{
                              width: 300,
                              height: 50,
                              fontSize: "1.2rem",
                              mt: 5,
                              fontFamily: "SeoulNamsanM",
                              fontWeight: "bold",
                              backgroundColor: buttonColor,
                              animation: isShaking ? `${shakeAnimation} 0.5s ease` : "none",
                          }}>
                          {buttonText}
                      </Button>
                      <h4 style={{marginTop: 50, color: "grey"}}>계정이 없으신가요?</h4>
                      <Button
                          variant="outlined"
                          sx={{
                              weight: 50,
                              width: 200,
                              mb: 10,
                              fontFamily: "SeoulNamsanM",
                              fontWeight: "bold",
                          }}
                          onClick={() => {
                              Redirect(".?pid=6", false);
                          }}
                      >
                      회원가입
                      </Button>
                  </Box>
              </Box>
          </form>
      </React.Fragment>
  );
}