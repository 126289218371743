import TopBar from '../components/Topbar';
import Gong4 from '../components/Gong4';

//PID: 7
export default function Help() {
    return (
      <div>
          <TopBar/>
          <Gong4/>
      </div>
    );
  }
  