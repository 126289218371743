import TopBar from '../components/Topbar';
import Gong4 from '../components/Gong4';

//PID: 5
export default function DataRoom() {
  return (
    <div>
        <TopBar/>
        <Gong4/>
    </div>
  );
}
